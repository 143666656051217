import React, { useRef, useState, useCallback } from 'react'
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { softShadows, MeshWobbleMaterial } from 'drei'
import { useSpring, a } from 'react-spring/three'
import { lerp } from '../helpers'

softShadows()

const SpinningMesh = ({ position, args, color, speed, mouse }) => {
  const mesh = useRef(null)
  const [expand, setExpand] = useState(false)
  const props = useSpring({
    scale: expand ? [1.4, 1.4, 1.4] : [1, 1, 1]
  })

  useFrame(() => {
    mesh.current.rotation.x = mesh.current.rotation.y += 0.01
  })

  return (
    <a.mesh
      ref={mesh}
      onPointerOver={() => setExpand(!expand)}
      onPointerOut={() => setExpand(!expand)}
      scale={props.scale}
      castShadow
      position={position}
    >
      <boxBufferGeometry attach="geometry" args={args} />
      <MeshWobbleMaterial attach="material" color={color} speed={speed} factor={0.6} />
    </a.mesh>
  )
}

// const Camera = ({ mouse }) => {
//   const { size, viewport } = useThree()
//   const aspect = size.width / viewport.width

//   const rotationSpeed = 0.01

//   useFrame(({ clock, camera, scene }) => {
//     // camera.position.z = 50 + Math.sin(clock.getElapsedTime()) * 30
//     // camera.position.x = lerp(camera.position.x, mouse.current[0] / aspect, 0.1)
//     // camera.rotation.x += 0.5 * (((1 - mouse.current[1]) * 0.002) - camera.rotation.x)
//     // camera.rotation.y += 0.5 * (((1 - mouse.current[0]) * 0.002) - camera.rotation.y)

//     // camera.position.x = camera.position.x * Math.cos(rotationSpeed) + camera.position.z * Math.sin(rotationSpeed)
//     // camera.position.z = camera.position.z * Math.cos(rotationSpeed) - camera.position.x * Math.sin(rotationSpeed)

//     // camera.updateProjectionMatrix()
//     // camera.lookAt(scene.position)
//   })
    
//   return null
// }

const CursorLight = ({ mouse }) => {
  const light = useRef(null)
  const { size, viewport } = useThree()
  const aspect = {
    width: size.width / viewport.width,
    height: size.height / viewport.height
  }

  useFrame(() => {
    light.current.position.x = lerp(light.current.position.x, mouse.current[0] / aspect.width, 0.1)
    light.current.position.y = lerp(light.current.position.y, -mouse.current[1] / aspect.height, 0.1)
    light.current.position.z = 2
  })

  return (
    <pointLight ref={light} distance={8} intensity={5} color="lightblue">
      <mesh>
        <sphereBufferGeometry attach="geometry" args={[0.3, 32, 32]} />
        <meshBasicMaterial attach="material" color="white" />
      </mesh>
    </pointLight>
  )
}

function Background({ color }) {
  const { viewport } = useThree()

  return (
    <mesh scale={[viewport.width, viewport.height, 1]}>
      <planeGeometry attach="geometry" args={[1, 1]} />
      <a.meshStandardMaterial attach="material" color="#27282F" depthTest={false} />
    </mesh>
  )
}

const GL = () => {
  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(({ clientX : x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])

  return (
    <>
    <div style={{width: '100%', height: '100vh'}} onMouseMove={onMouseMove}>
      <Canvas
        shadowMap
        colorManagement
        camera={{ position: [0, 0, 10], fov: 80}}
      >
        <Background/>
        <ambientLight intensity={0.05} />
        {/* <directionalLight
          castShadow
          position={[0, 10, 0]}
          intensity={0.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        /> */}
        <group>
          <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -3, 0]}>
            <planeBufferGeometry attach="geometry" args={[100, 100]} />
            <shadowMaterial attach="material" opacity={0.2} />
          </mesh>

          <SpinningMesh mouse={mouse} position={[0, 1, 0]} args={[3, 2, 1]} color="lightblue" speed={2} />
          <SpinningMesh mouse={mouse} position={[-5, 1, -5]} color="pink" speed={6} />
          <SpinningMesh mouse={mouse} position={[5, 1, -5]} color="pink" speed={6} />
        </group>

        {/* <Camera mouse={mouse} /> */}
        <CursorLight mouse={mouse} />
      </Canvas>
    </div>
    </>
  )
}

export default GL